import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import './HowItWorks.css';

class HowItWorks extends Component {

  render() {

    return (
      <React.Fragment>
        <div className="how-it-works-container">
          <h1 className="how-it-works">How It Works</h1>
          <div>1. DOWNLOAD THE EXTENSION FOR <a
            href="https://chrome.google.com/webstore/detail/slabwatch-show-ebay-best/djnkjimeimidbimfhoidjhfahoooinpb"
               className="how-it-works-browser">CHROME</a> OR <a
              href="https://addons.mozilla.org/en-US/firefox/addon/slabwatch-ebay-best-offer/"
              className="how-it-works-browser">FIREFOX</a></div>
          <div>2. SEARCH FOR YOUR CARDS ON EBAY</div>
          <div>3. FILTER BY SOLD ITEMS</div>
          <div>4. USE EXTENSION TO VIEW ACTUAL SOLD PRICE</div>
          <div>5. SIGN UP TO GET NOTIFIED OF NEW FEATURES, NEWS, AND GIVEAWAYS</div>

          <iframe className="how-it-works-video" title="How It Works" src="https://www.youtube.com/embed/_a9o2entd8M" frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen>
          </iframe>
        </div>
      </React.Fragment>
    )
  }
}

export default HowItWorks;
