import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import axios from 'axios';

class UploadCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_type: 'scrape'
    };
  }

  handleChange = (e) => {
    this.setState({file_type: e.target.value});
  };

  onChangeHandler = (e) => {
    this.setState({
      selectedFile: e.target.files,
    })
  };

  onSubmit = () => {
    let info = {
      company: this.state.company, brand: this.state.brand, year: this.state.year, sport: this.state.sport
    };
    const data = new FormData();
    for (const key of Object.keys(this.state.selectedFile)) {
      data.append('file', this.state.selectedFile[key])
    }
    axios.post(process.env.REACT_APP_BACKEND + 'upload/csv', data).catch((error) => {
      console.log(error);
    })
  };

  changeText = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  render() {
    return (
      <React.Fragment>
        <select value={this.state.file_type} onChange={this.handleChange}>
          <option value="scrape">Scrape</option>
          <option value="card_brand">CardBrand</option>
          <option value="clean_checklist">Clean Checklist</option>
        </select>
        <input type="file" name="file" multiple onChange={this.onChangeHandler}/>
        <input value={this.state.company} placeholder="Company" name="company" type="text" onChange={this.changeText} />
        <input value={this.state.brand} placeholder="Brand" name="brand" type="text" onChange={this.changeText} />
        <input value={this.state.year} placeholder="Year" name="year" type="text" onChange={this.changeText} />
        <input value={this.state.sport} placeholder="Sport" name="sport" type="text" onChange={this.changeText} />
        <button onClick={this.onSubmit}>Submit</button>
      </React.Fragment>
    )
  }
}

export default UploadCSV;
