export default function(state = {name: null, brand: null, variation: null, link: []}, action) {
  switch (action.type) {
    case 'SET_NAME':
      state.name = action.payload;
      return state;
    case 'SET_BRAND':
      state.brand = action.payload;
      return state;
    case 'SET_VARIATION':
      state.variation = action.payload;
      return state;
    case 'SET_LINK':
      state.link = action.payload;
      return state;
    case 'SET_FOUND_LINK':
      state.found = action.payload;
      return state;
    default:
      return state;
  }
}
