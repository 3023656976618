import React, { useEffect, useState } from 'react';
import {Row, Col, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import './HomeHottest.css';
import axios from 'axios';

function HomeHottestCards(props) {
  let [allData, setData] = useState([]);
  let [found_data, setFound] = useState(false);

  useEffect(() => {
    if (!found_data) {
      getHottest();
    }
  })

  const getHottest = () => {
    axios.get('https://ihf4f3gvm3.execute-api.us-west-2.amazonaws.com/dev').then((response) => {
      allData = [];
      const items = response.data.Items.sort((a, b) => Math.abs(a.percent_change) < Math.abs(b.percent_change) ? 1 : -1).slice(0, 10);
      for (let item of items) {
        if (item.percent_change < 0) {
          allData.push({name: item.title, percent_change: <span className="negative-percent">-%{Math.abs(item.percent_change)}</span>})
        } else {
          allData.push({name: item.title, percent_change: <span className="positive-percent">+%{item.percent_change}</span>})
        }
      }
      allData.push({name: '', percent_change: <Link className="home-see-all" to={props.seeAll}>See All</Link>})
      setData(allData);
    }).catch((error) => {
      console.log('in this error');
      console.log(error);
    });
    setFound(true);
  };

  const columns = [
    {dataField: 'name', text: props.title},
    {dataField: 'percent_change', text: '% Change', align: 'right', headerAlign: 'right'},
  ];

  return (
    <React.Fragment>
      <Col xs={12} sm={6}>
        <BootstrapTable
          classes="home-table"
          keyField="name"
          data={ allData }
          columns={ columns }
          headerClasses="home-table-header" rowClasses="home-table-row"
          condensed
        />
      </Col>
    </React.Fragment>
  );
}

export default HomeHottestCards;
