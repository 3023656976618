import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import './BestOfferDetails.css';

class BestOfferDetails extends Component {
  formatPrice = (price) => {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0,
      //maximumFractionDigits: 0,
    });
    try {
      return formatter.format(price);
    }catch(err) {
      console.log(err);
      return 'N/A';
    }
  };

  render() {
    const data = this.props.data;
    const actual_price = this.formatPrice(data.actual_price);
    const original_price = this.formatPrice(data.original_price);
    const shipping = this.formatPrice(data.shipping);
    let difference = 'N/A';
    if (data.original_price && data.actual_price) {
      if (data.original_price >= data.actual_price) {
        difference = `${Math.floor((1 - data.actual_price / data.original_price) * 100)}%`;
      }
      else {
        difference = `${Math.floor((data.actual_price / data.original_price - 1) * 100)}%`;
      }
    }

    return (
      <React.Fragment>
        <Col xs={12} md={{span: 8}}>
          <div className="best-offer-details-container">
            <div className="best-offer-details-title">{data.title}</div>
            <div className="best-offer-details-small-text">Sold Price</div>
            <div className="best-offer-details-actual-price">{actual_price}</div>
            <div className="best-offer-details-small-text">Original Price</div>
            <div className="best-offer-details-typical-price">{original_price}</div>
            <div className="best-offer-details-small-text">Shipping</div>
            <div className="best-offer-details-typical-price">{shipping}</div>
            <div className="best-offer-details-small-text">Difference</div>
            <div className="best-offer-details-difference">{difference}</div>
            <hr className="best-offer-details-line"/>
          </div>
        </Col>
      </React.Fragment>
    )
  }
}

export default BestOfferDetails;
