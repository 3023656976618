import React from 'react';
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import CshLogo from "../../assets/csh-logo.svg";
import NavLogo from "../../assets/logo-slabwatch-small.svg";
import './Navigation.css';

function Navigation() {
  let setNavLink = (href, className, title) => {
    return <Nav.Link className={className} href={href}>{title}</Nav.Link>
  };

  let setActivityNavDropdown = () => {
    let hrefs = ['/unusual-activity/player', '/unusual-activity/card'];
    let className = 'navbar-link';

    if (hrefs.includes(window.location.pathname)) {
      className = 'navbar-link active-nav-link';
    }

    return <NavDropdown title="Most Activity" id="basic-nav-dropdown" className={className}>
      <NavDropdown.Item href="/unusual-activity/player">Player</NavDropdown.Item>
      <NavDropdown.Item href="/unusual-activity/card">Card</NavDropdown.Item>
    </NavDropdown>
  };

  let mapNavLinks = (nav_link_dict, ) => {
    return nav_link_dict.map((link) => {
      for (let key in link) {
        if (window.location.pathname === key) {
          className = 'navbar-link active-nav-link';
        }
        else {
          className = 'navbar-link';
        }
        if (link[key].getNav) {
          return link[key].getNav(key, className, link[key].title)
        }
        else {
          return link[key]();
        }
      }
    });
  };

  let nav_left_link_dict = [
    {'/': {getNav: setNavLink, title: 'Home'}},
    // {'/search': {getNav: setNavLink, title: 'Search'}},
    // {'/search-transactions': {getNav: setNavLink, title: 'Search Transactions'}},
    // {'/largest-movers': {getNav: setNavLink, title: 'Largest Movers'}},
    // {'/unusual-activity/player': setActivityNavDropdown},
  ];

  let nav_right_link_dict = [
    {'/how-it-works': {getNav: setNavLink, title: 'How It Works'}}
  ];

  let className;
  let nav_left_links = mapNavLinks(nav_left_link_dict);
  let nav_right_links = mapNavLinks(nav_right_link_dict);

  return (
    <React.Fragment>
      <Navbar collapseOnSelect expand="lg" className="navbar-css">
        <NavDropdown title="Install Our Extension" id="basic-nav-dropdown" className="nav-extension-mobile">
          <NavDropdown.Item target="_blank" href="https://chrome.google.com/webstore/detail/slabwatch-show-ebay-best/djnkjimeimidbimfhoidjhfahoooinpb">Chrome Extension</NavDropdown.Item>
          <NavDropdown.Item target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/slabwatch-ebay-best-offer/">Firefox Extension</NavDropdown.Item>
        </NavDropdown>
        
        <img className="csh-logo" src={CshLogo} alt="logo" />

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            {nav_left_links}
            <Nav.Link className="navbar-link" href="https://discord.com/invite/KFbU72q">Discord</Nav.Link>
            {/* {nav_right_links}
            <NavDropdown title="Install Our Extension" id="basic-nav-dropdown" className="nav-extension-web">
              <NavDropdown.Item target="_blank" href="https://chrome.google.com/webstore/detail/slabwatch-show-ebay-best/djnkjimeimidbimfhoidjhfahoooinpb">Chrome Extension</NavDropdown.Item>
              <NavDropdown.Item target="_blank" href="https://addons.mozilla.org/en-US/firefox/addon/slabwatch-ebay-best-offer/">Firefox Extension</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </React.Fragment>
  );
}

export default Navigation;
