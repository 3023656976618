import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import './BestOfferImages.css';

class BestOfferImages extends Component {

  render() {
    return (
      <React.Fragment>
        <Col xs={12} md={{span: 4}} className="text-align-center">
          <img className="best-offer-image" src={this.props.images} />
        </Col>
      </React.Fragment>
    )
  }
}

export default BestOfferImages;
