import React, {Component} from 'react';
import './Home.css';
import {Row, Col} from "react-bootstrap";
import MainLogo from "../../assets/logo.slabwatch.svg"
import CardSpread from "../../assets/card-spread.png"
import HomeTopMovers from "./home-top-movers/HomeTopMovers";
import HomeHottestPlayers from "./home-hottest/HomeHottestPlayers";
import HomeHottestCards from "./home-hottest/HomeHottestCards";
import BestOfferInput from "../best-offer/best-offer-input/BestOfferInput";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xs={12}>
          <Row>
            <Col className="text-align-center" xs={12} lg={{span: 10, offset: 1}}>
              <img className="home-main-logo" src={MainLogo} />
              <div className="home-main-text">SLABWATCH</div>
              <div className="home-main-subtext">POWERED BY CENTER STAGE</div>
              <img className="home-main-card-spread" src={CardSpread} />
            </Col>
          </Row>
          <div className="home-input-container">
            <BestOfferInput />
          </div>
          <button className="download-app-btn">
            <a className="btn-text" href="https://apps.apple.com/us/app/center-stage-sports-cards/id1577144149" target="_blank" rel="noopener noreferrer">
              <span className="apple-icon"></span>
              Available on the App store
            </a>
          </button>
          {/* <HomeTopMovers title="Top Movers" />
          <Row className="home-hottest-container">
            <HomeHottestPlayers title="Hottest Players (Qty Sold)" seeAll="/unusual-activity/player"/>
            <HomeHottestCards title="Hottest Cards (Qty Sold)" seeAll="/unusual-activity/card"/>
          </Row>
          <HomeTopMovers title="Spotlight" /> */}
        </Col>
      </React.Fragment>
    )
  }
}

export default Home;
