import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import BestOffer from './components/best-offer/BestOffer';
import Home from "./components/home/Home";
import {Container, Navbar, Nav, NavDropdown, Row, Col} from "react-bootstrap";
import PriceTracker from "./components/price-tracker/PriceTracker";
import HowItWorks from "./components/how-it-works/HowItWorks";
import CardSearch from "./components/card-search/CardSearch";
import SearchTransactions from "./components/search-transactions/SearchTransactions";
import UploadCSV from "./components/upload-csv/UploadCSV";
import UnusualActivity from "./components/unusual-activity/UnusualActivity";
import UnusualActivityPlayer from "./components/unusual-activity/UnusualActivityPlayer";
import LargestMovers from "./components/largest-movers/LargestMovers";
import usePageTracking from "./components/usePageTracking";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
import MoreFeatures from "./components/more-features/MoreFeatures";
import NewsLetter from "./components/newsletter/NewsLetter";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";

function App(props) {
  usePageTracking();

  return (
    <React.Fragment>
      <Navigation />
      <Container fluid className="">
        <ScrollToTop />
        <Row className="height-100">
          <Col md={{span: 12, offset: 0}} lg={{span: 10, offset: 1}} className="main-section">
            <Switch>
              {/*<Route exact path="/upload/csv/cards" component={UploadCSV} />*/}
              <Route exact path="/how-it-works" component={HowItWorks} />
              {/* <Route exact path="/largest-movers" component={LargestMovers} />
              <Route exact path="/unusual-activity/card" component={UnusualActivity} />
              <Route exact path="/unusual-activity/player" component={UnusualActivityPlayer} /> */}

              {/*temp disable, will release later*/}
              {/* <Route exact path="/card/:title" component={PriceTracker} />*/}
              {/* <Route exact path="/search" component={CardSearch} /> */}
              {/* <Route exact path="/search-transactions" component={SearchTransactions} /> */}
              {/* <Route exact path="/new-features" component={MoreFeatures} /> */}
              {/*<Route exact path="/newsletter" component={NewsLetter} />*/}
              <Route exact path="/" component={Home} />
              <Route exact path="*" component={BestOffer}/>
            </Switch>
          </Col>
          {/*<Footer />*/}
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default App;
