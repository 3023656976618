import React from 'react';
import {Row, Col} from "react-bootstrap";
import './SearchTransactionsResultItem.css';
import Moment from 'moment';

const SearchTransactionsResultItem = ({ card }) => {
  const title = card._source.title;
  const thumbnail = card._source.thumbnail;
  const date = Moment(card._source.created_at).format('LL');

  let price;
  if (card._source.price === "None" || card._source.price === undefined) {
    price = 'N/A';
  }
  else {
    price = `${parseFloat(card._source.price).toFixed(2)} USD`;
  }

  const redirectToCardDetail = () => {
    window.location.href = process.env.REACT_APP_FRONTEND + 'card/' + title;
  };

  return (
    <React.Fragment>
      <Row className="search-transactions-item">
      {/* <Row className="search-transactions-item" onClick={redirectToCardDetail}> */}
        <Col xs={3} lg={2}>
          <img className="search-transactions-image" src={ thumbnail } />
        </Col>
        <Col xs={9} lg={10}>
          <Row className="search-transactions-title-container">
            <Col xs={12}>
              <span className="search-transactions-title">{ title }</span>
            </Col>
          </Row>
          <Row className="search-transactions-price-container">
            <Col xs={12} md={6}>
              <div className="search-transactions-price-details">
                <span className="search-transactions-price">{ price }</span>
              </div>
              <div className="search-transactions-date">Sale Date: { date }</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="search-transactions-separator" />
    </React.Fragment>
  )
};

export default SearchTransactionsResultItem
