import React from 'react';
import axios from 'axios';
import {Row, Col} from "react-bootstrap";
import CardSearchResultItem from "./card-tracker-results/CardSearchResultItem";
import './CardSearch.css';
import ReactPaginate from "react-paginate";

class CardSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit : 4,
      pageCount: 3,
      currentList: [],
      currPage: 0,
      input: '',
      searchResults: []
      // searchResults: [{"_index":"test-index","_type":"_doc","_id":"164513090454","_score":4.6064777,"_source":{"title":"2006 Lebron James Upperdeck #5 (Kid Lebron)"}},{"_index":"test-index","_type":"_doc","_id":"324359644405","_score":4.2739835,"_source":{"title":"Lebron James Liftoff"}},{"_index":"test-index","_type":"_doc","_id":"193737624163","_score":4.257842,"_source":{"title":"2004/05 Lebron James Upper Deck #26 (2nd Year Lebron) PSA??"}},{"_index":"test-index","_type":"_doc","_id":"373319095997","_score":4.195361,"_source":{"title":"LeBron James 2005-06 Upper Deck LeBron James #LJ21 - Cavaliers - Mint"}},{"_index":"test-index","_type":"_doc","_id":"193737622797","_score":4.117689,"_source":{"title":"2004/05 Lebron James Upper Deck R-Class #13 (2nd Year Lebron)"}},{"_index":"test-index","_type":"_doc","_id":"393006522627","_score":4.1100254,"_source":{"title":"Lebron James Mosaic Base"}},{"_index":"test-index","_type":"_doc","_id":"303762921114","_score":4.0851774,"_source":{"title":"Lebron James Actual Charger"}},{"_index":"test-index","_type":"_doc","_id":"363169802943","_score":4.0663543,"_source":{"title":"lebron james marquee pink"}},{"_index":"test-index","_type":"_doc","_id":"233764694637","_score":4.0663543,"_source":{"title":"2008 Lebron James Chalk"}},{"_index":"test-index","_type":"_doc","_id":"393008821450","_score":3.997639,"_source":{"title":"2003 - 2004 LeBron James Rookie Card Upper Deck Lebron Diary LJ13 NBA Basketball"}}]
    }
  }

  getName = async (e) => {
    e.preventDefault();
    // Replace any spaces from the user input with +
    let val = this.state.input.replace(/\$/g, "+");

    await axios.get(`https://l45k3vep0m.execute-api.us-west-2.amazonaws.com/dev/card-search?query=${val}`)
    .then(async (response) => {
      this.setState({
        searchResults : response.data,
        pageCount : response.data.length / this.state.limit,
        currentList : response.data.slice(0, this.state.limit),
        currPage : 0,
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  handlePageClick = (data) => {
    let selected = data.selected;
    this.setState({
      currentList: this.state.searchResults.slice(selected * this.state.limit, selected * this.state.limit + this.state.limit),
      currPage: selected,
    });
  };

  inputHandler = (e) => {
    this.setState({
      input: e.target.value
    });
  };

  scrollToTop = () => {
    document.querySelector("#root").scrollTo(0,0);
  };

  render() {
    let aboutResults, searchResultRows = [], paginate;
    let searchResults = this.state.searchResults;
    if (searchResults.length > 0) {
      let start = this.state.currPage * this.state.limit;

      for (let i = start; i < start + this.state.limit; i++) {
        if (searchResults[i]) {
          searchResultRows.push(
            <CardSearchResultItem card={searchResults[i]} />
          )
        }
      }

      aboutResults = <div className="card-search-about-results">ABOUT {this.state.searchResults.length} RESULTS</div>;

      paginate = <ReactPaginate
        previousLabel={'PREV'}
        nextLabel={'NEXT'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={ this.state.pageCount }
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        disableInitialCallback={true}
        onPageChange={ this.handlePageClick }
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active-paginate'}
        pageLinkClassName={'pagination-link'}
        previousLinkClassName={'pagination-prev'}
        nextLinkClassName={'pagination-next'}
      />
    }

    //note:  We should probably add a loading gif or something to show that we are actively searching for results

    return (
      <React.Fragment>
        <Row className="tracker-container">
          <Col xs={12} lg={{offset: 1, span: 10}} xl={{offset: 2, span: 8}}>
            <form className="search-form" onSubmit={this.getName}>
              <input type="text" value={this.state.search} placeholder="FIND A CARD" className="search-input"
                     onChange={this.inputHandler}/>
            </form>
            { aboutResults }
            {searchResultRows}
          </Col>
        </Row>
        <Row>
          <Col xs={8} lg={{offset: 1, span: 6}} xl={{offset: 2, span: 4}}>
            {paginate}
          </Col>
          <Col className="card-search-top-container" xs={4}>
            <span className="card-search-top" onClick={this.scrollToTop}>BACK TO TOP</span>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default CardSearch;
