import React from 'react';
import {Row, Col} from "react-bootstrap";
import RedArrow from '../../../assets/icon-downer.svg';
import GreenArrow from '../../../assets/icon-upper.svg';
import './CardSearchResultItem.css';

const CardSearchResultItem = ({ card }) => {
  const title = card._source.title;

  let priceColor = 'card-search-positive';
  let arrow = GreenArrow;

  if (card._source.price >= 0) {
    priceColor = 'card-search-negative';
    arrow = RedArrow;
  }

  const redirectToCardDetail = () => {
    window.location.href = process.env.REACT_APP_FRONTEND + 'card/' + title;
  };

  //will need to wait for index to include images and potentially price, for now it will utilize dummy data
  //will need to update price, price change, percent change, and image

  return (
    <React.Fragment>
      <Row className="card-search-item">
      {/* <Row className="card-search-item" onClick={redirectToCardDetail}> */}
        <Col xs={3} lg={2}>
          <img className="card-search-image" src="https://theravinela.s3.us-west-2.amazonaws.com/3fc8f8e983828dd572f4f5e2cfbc7070.jpg" />
        </Col>
        <Col xs={9} lg={10}>
          <Row className="card-search-title-container">
            <Col xs={12}>
              <span className="card-search-title">{title}</span>
            </Col>
          </Row>
          <Row className="card-search-price-container">
            <Col xs={12} md={6}>
              <div className="card-search-detail-header">EST VALUE</div>
              <div className="card-search-price-details"><span className="card-search-price">$600</span> <span className="card-search-price-change">+$72</span></div>
            </Col>

            <Col xs={12} md={6}>
              <div className="card-search-detail-header">CHANGE (1 WK)</div>
              <div className="card-search-percent-container">
                <img className="card-search-arrow" src={arrow} /><span className={priceColor}>10%</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="card-search-separator" />
    </React.Fragment>
  )
};

export default CardSearchResultItem