import React, {Component} from 'react';
import './MoreFeatures.css';
import MoreFeaturesBlur from '../../assets/image-more-features-blur.png'
import {Link} from "react-router-dom";

class MoreFeatures extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="best-offer-more-features-container">
          <img className="best-offer-more-features-image" src={MoreFeaturesBlur} />
          <div className="best-offer-more-features-text">
            <div className="best-offer-more-features-coming-soon">MORE FEATURES COMING SOON!</div>
            <div className="best-offer-more-features-subtext">MORE DATA, SALES DATA, TRENDS, AND <span>FREE</span> GIVEAWAYS!</div>
            <div className="best-offer-more-features-subtext">SIGN UP TO GET NOTIFIED</div>
            <Link to="/newsletter" className="best-offer-more-features-button">SIGN UP FOR OUR NEWSLETTER</Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MoreFeatures;
