import React, { Component } from 'react';
import {Row, Col, Card} from "react-bootstrap";
import SmallLogo from '../../assets/logo-slabwatch-small.svg'
import DownloadIcon from '../../assets/icon-download.svg'
import './Footer.css';
import {Link} from "react-router-dom";

function Footer() {
  return (
    <React.Fragment>
      <Col xs={12} className="footer-container">
        <Row>
          <Col xs={12} lg={{offset: 1, span: 10}}>
            <Row>
              <Col xs={6}>
                <Link to="/" className="footer-link">
                  <img className="footer-logo" src={SmallLogo} />
                </Link>
              </Col>
              <Col xs={6}>
                <div className="footer-newsletter-text">WANT FREE CARDS?</div>
                <div className="footer-newsletter-text">GET NOTIFIED OF NEW FEATURES?</div>
              </Col>
            </Row>
            <Row className="footer-row-2">
              <Col xs={6}>
                <a href="https://chrome.google.com/webstore/detail/slabwatch-show-ebay-best/djnkjimeimidbimfhoidjhfahoooinpb" className="footer-extension">INSTALL EXTENSION <img src={DownloadIcon} className="footer-extension-icon"/></a>
              </Col>
              <Col xs={6}>
                <Link to="/newsletter" className="footer-newsletter-button-web">SIGN UP FOR OUR NEWSLETTER</Link>
                <Link to="/newsletter" className="footer-newsletter-button-mobile">SIGN UP</Link>
              </Col>
            </Row>
            <Row className="footer-links1">
              <Col xs={4} lg={2}>
                <Link to="/" className="footer-link">HOME</Link>
              </Col>
              {/* <Col xs={4} lg={2}>
                <Link to="/unusual-activty/player" className="footer-link">MOST ACTIVITY</Link>
              </Col> */}
            </Row>
            <Row>
              <Col xs={4} lg={2}>
                <Link to="/" className="footer-link">BEST OFFER FINDER</Link>
              </Col>
              <Col xs={4} lg={2}>
                <Link to="/how-it-works" className="footer-link">HOW IT WORKS</Link>
              </Col>
            </Row>
            <Row>
              {/* <Col xs={4} lg={2}>
                <Link to="/largest-movers" className="footer-link">LARGEST MOVERS</Link>
              </Col> */}
              <Col xs={4} lg={2}>
                <a href="https://discord.com/invite/KFbU72q" className="footer-link">DISCORD</a>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="footer-company">CENTER STAGE HOBBY INC 2021</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
}

export default Footer;


{/*<Col xs={12} sm={{span: 10, offset: 1}}>*!/*/}
  {/*  <Row>*/}
  {/*    <Col xs={8}>*/}
  {/*      <Row>*/}
  {/*        <Col xs={12}>*/}
  {/*          <Link to="/" className="footer-link">*/}
  {/*            <img className="footer-logo" src={SmallLogo} />*/}
  {/*          </Link>*/}
  {/*        </Col>*/}
  {/*        <Col xs={12}>*/}
  {/*          <a href="https://chrome.google.com/webstore/detail/slabwatch-show-ebay-best/djnkjimeimidbimfhoidjhfahoooinpb" className="footer-extension">INSTALL EXTENSION <img src={DownloadIcon} className="footer-extension-icon"/></a>*/}
  {/*        </Col>*/}
  {/*        <Col xs={6} lg={2}>*/}
  {/*          <Link to="/" className="footer-link">HOME</Link>*/}
  {/*        </Col>*/}
  {/*        <Col xs={6} lg={2}>*/}
  {/*          <Link to="/" className="footer-link">BEST OFFER FINDER</Link>*/}
  {/*        </Col>*/}
  {/*        <Col xs={6} lg={2}>*/}
  {/*          <Link to="/largest-movers" className="footer-link">LARGEST MOVERS</Link>*/}
  {/*        </Col>*/}
  {/*        <Col xs={6} lg={2}>*/}
  {/*          <Link to="/unusual-activty/player" className="footer-link">MOST ACTIVITY</Link>*/}
  {/*        </Col>*/}
  {/*        <Col xs={6} lg={2}>*/}
  {/*          <Link to="/how-it-works" className="footer-link">HOW IT WORKS</Link>*/}
  {/*        </Col>*/}
  {/*        <Col xs={6} lg={2}>*/}
  {/*          <a href="https://discord.com/invite/KFbU72q" className="footer-link">DISCORD</a>*/}
  {/*        </Col>*/}
  {/*      </Row>*/}
  {/*    </Col>*/}
  {/*    <Col className="footer-right-side" xs={4}>*/}
  {/*      <div className="footer-newsletter-text">WANT FREE CARDS?  GET NOTIFIED OF NEW FEATURES?</div>*/}
  {/*      <Link to="/newsletter" className="footer-newsletter-button-web">SIGN UP FOR OUR NEWSLETTER</Link>*/}
  {/*      <Link to="/newsletter" className="footer-newsletter-button-mobile">SIGN UP</Link>*/}
  {/*      <div className="footer-company">CENTER STAGE HOBBY INC 2021</div>*/}
  {/*    </Col>*/}
  {/*    /!*<Col xs={12} className="footer-company-container">*!/*/}
  {/*    /!*  <div className="footer-company">CENTER STAGE HOBBY INC 2021</div>*!/*/}
  {/*    /!*</Col>*!/*/}
  {/*  </Row>*/}
  {/*</Col>*/}
