import React, {Component} from 'react';
import axios from 'axios';
import {Row, Col, ListGroup, Tabs, Tab} from 'react-bootstrap';
import './PriceTracker.css';
import PriceTrackerGraph from "./PriceTrackerGraph";

class PriceTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      graph: [],
      title: '',
    };
  }

  componentDidMount() {
    // change ` back to #..
    const str = this.props.match.params.title;
    // let card = this.props.match.params;
    let title = str.replace(/\`/g, "#");

    axios.get(`https://l45k3vep0m.execute-api.us-west-2.amazonaws.com/dev/card-search?query=${title}`)
    .then(async (response) => {

    })
    .catch(function (error) {
      console.log(error);
    });

    let data = [
      {month: 'Jan 2020', PSA10: 1000, PSA9: 300, RAW: 200},
      {month: 'Feb 2020', PSA10: 1500, PSA9: 500, RAW: 400},
      {month: 'Mar 2020', PSA10: 1600, PSA9: 550, RAW: 500},
      {month: 'Apr 2020', PSA10: 1700, PSA9: 600, RAW: 550},
      {month: 'May 2020', PSA10: 1800, PSA9: 650, RAW: 600},
      {month: 'Jun 2020', PSA10: 2000, PSA9: 700, RAW: 600},
      {month: 'Jul 2020', PSA10: 2200, PSA9: 800, RAW: 700},
      {month: 'Aug 2020', PSA10: 2500, PSA9: 900, RAW: 800},
      {month: 'Sep 2020', PSA10: 2000, PSA9: 600, RAW: 500},
      {month: 'Oct 2020', PSA10: 1800, PSA9: 500, RAW: 400},
      {month: 'Nov 2020', PSA10: 1000, PSA9: 300, RAW: 200},
      {month: 'Dec 2020', PSA10: 1200, PSA9: 400, RAW: 300},
    ];

    this.setState({
      data: data,
      graph: data,
      title: title,
    })
  }

  setRoverLink = () => {
    window._epn = {campaign: process.env.REACT_APP_EBAY_CAMPAIGN_ID};
    const script = document.createElement("script");
    script.src = "https://epnt.ebay.com/static/epn-smart-tools.js";
    script.async = true;

    document.body.appendChild(script);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };

  setTags = (tags) => {
    return tags.map((tag) => {
      return <span className="price-tracker-tag">{tag}</span>
    })
  };

  filterDataByDate = (month) => {
    month = month || 12;
    let filteredDate = [];
    const dataArr = this.state.data;

    for(let i = dataArr.length - 1; i >= dataArr.length - month; i--) {
      filteredDate.unshift(dataArr[i]);
    }

    this.setState({
      graph: filteredDate,
    })
  };

  // filterDataByDate = (month) => {
  //   month = month || 12;
  //   let filteredDate = [];
  //   const dataArr = this.state.data;
  //   const numFilter = moment().subtract(month,'months').format("MMM YYYY");

  //   // firfox have issue on this part.
  //   for(let i = 0; i < dataArr.length; i++) {
  //     let eachMonth = dataArr[i].month;

  //     // problem with "moment(eachMonth).isAfter(numFilter)" line on firefox
  //     // console.log('is after?: ', moment(eachMonth).isAfter(numFilter));
  //     if(moment(eachMonth, "MMM YYYY").isAfter(numFilter)) {
  //       filteredDate.push(dataArr[i]);
  //     }
  //   }
  //   console.log('dataArr: ', dataArr);
  //   console.log('filteredDate: ', filteredDate); // it shows EMPTY on firfox

  //   this.setState({
  //     graph: filteredDate,
  //   })
  // };

  handleGraphMonthClick = (e) => {
    this.filterDataByDate(e.target.value)
  }

  render() {
    let tag_array = ['Zion Williamson', 'Panini', 'Prizm', 'Silver'];
    let tags = this.setTags(tag_array);
    return (
      <React.Fragment>
        <Row className="price-tracker-container">
          <Col xs={4} sm={3}>
            <img className="price-tracker-image" src="https://theravinela.s3.us-west-2.amazonaws.com/3fc8f8e983828dd572f4f5e2cfbc7070.jpg" />
          </Col>

          <Col xs={8} md={9}>
            <div className="price-tracker-title">{this.state.title}</div>
            <PriceTrackerGraph x_axis="interval" y_axis="avg" data={this.state.graph} onClickHandler={this.handleGraphMonthClick}/>
          </Col>

          {/* <Col xs={8} md={9}>
            <div className="price-tracker-title">{this.state.title}</div>
          </Col>
          <Col xs={{span: 12, offset: 0}}>
            <PriceTrackerGraph x_axis="interval" y_axis="avg" data={this.state.data} />
          </Col> */}

          <Col xs={12} className="price-tracker-analytic-section">
            <Row>
              <Col xs={4}>
                <div className="price-tracker-analytic-title">TYPICAL SELLING PRICE (LIFETIME)</div>
                <div className="price-tracker-analytic">$128.50</div>
              </Col>
              <Col xs={4}>
                <div className="price-tracker-analytic-title">TYPICAL SELLING PRICE RANGE (LIFETIME)</div>
                <div className="price-tracker-analytic">$98.50 - $180.11</div>
              </Col>
              <Col xs={4}>
                <div className="price-tracker-analytic-title">TRENDING</div>
                <div className="price-tracker-analytic">12%</div>
              </Col>
            </Row>
            <hr className="price-tracker-separator"/>
          </Col>

          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <div className="price-tracker-analytic-title">12 MONTH STATS</div>
              </Col>
              <Col xs={4}>
                <div className="price-tracker-analytic-title"># OF SALES</div>
                <div className="price-tracker-analytic">23,591</div>
              </Col>
              <Col xs={4}>
                <div className="price-tracker-analytic-title"># OF CARDS (ESTIMATED)</div>
                <div className="price-tracker-analytic">34,581</div>
              </Col>
              <Col xs={4}>
                <div className="price-tracker-analytic-title">SEARCHED</div>
                <div className="price-tracker-analytic">824,389</div>
              </Col>
            </Row>

            <hr className="price-tracker-separator"/>

            <Row>
              <Col xs={8}>
                <div className="price-tracker-bottom-analytic">PACK 2019-20 PANINI PRIZM</div>
                <div className="price-tracker-bottom-analytic">RELEASE DATE: 05/12/19</div>
                <div className="price-tracker-bottom-analytic">RARITY: TOP 15%</div>
              </Col>
              <Col xs={4}>
                <span className="price-tracker-tag-title">TAGS </span> {tags}
              </Col>
            </Row>

          </Col>
        </Row>

      </React.Fragment>
    )
  }
}

export default PriceTracker;
