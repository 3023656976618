import React, { Component } from 'react';
import axios from 'axios';
import {Row, Col} from "react-bootstrap";
import './NewsLetter.css';

const initialState = {
  spend: null,
  collector_type: null,
  age: null,
  type_of_cards: [],
  first_name: null,
  last_name: null,
  email: null,
  agree: false,
  first_name_error: 'newsletter-error-hide',
  last_name_error: 'newsletter-error-hide',
  email_error: 'newsletter-error-hide',
  success_signup: false
};

class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  validateEmail = (email) => {
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validEmail.test(String(email).toLowerCase());
  };

  validateName = (name) => {
    const validName = /^[a-zA-Z]+$/;
    return validName.test(String(name).toLowerCase());
  };

  componentDidMount() {
    axios.post('https://r8b0edx4y3.execute-api.us-west-2.amazonaws.com/dev/test', {
      email: 'davjue@gmail.com123',
      firstname: 'Fred',
      lastname: 'Flintstone123'
    }).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    })
  }

  setUserInputRadio = (e) => {
    let id, value = e.target.value;
    e = e.target;
    while (!id || !e) {
      e = e.parentNode;
      if (e.id) {
        id = e.id;
      }
    }

    if (id) {
      this.setState({
        [id]: value
      })
    }
  };

  setUserInputText = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  };

  setUserInputCheckbox = (e) => {
    let type_of_cards = this.state.type_of_cards.slice(0);
    if (e.target.checked) {
      type_of_cards.push(e.target.value);
    }
    else {
      for (let i = type_of_cards.length-1; i >= 0; i--) {
        if (type_of_cards[i] === e.target.value) {
          type_of_cards.splice(i, 1);
        }
      }
    }

    this.setState({
      type_of_cards: type_of_cards
    })
  };

  setUserAgree = () => {
    this.setState({
      agree: !this.state.agree
    })
  };

  submitForm = () => {
    const validFirstName = this.validateName(this.state.first_name);
    const validLastName = this.validateName(this.state.last_name);
    const validEmail = this.validateEmail(this.state.email);
    if (validFirstName && validLastName && validEmail) {
      axios.post(`https://r8b0edx4y3.execute-api.us-west-2.amazonaws.com/dev/newsletters`, this.state)
      .then(() => {
        this.setState({
          success_signup: true
        })
      })
      .catch((err) => {
        console.log('An error has occurred:', err);
      })
    } else if (!validFirstName || !validLastName) {
      alert('Please enter a valid Name')
    } else {
      alert('Please enter a valid Email')
    }
  };

  checkIfAllFieldsFilled = () => {
    let state = this.state;
    if (state['type_of_cards'].length === 0) {
      return true;
    }

    for (let key in state) {
      if (key !== 'success_signup' && !state[key]) {
        return true;
      }
    }

    return false;
  };

  render() {
    let button_or_message = !this.state.success_signup ?
      <button className="newsletter-button" disabled={this.checkIfAllFieldsFilled()} onClick={this.submitForm}>SUBMIT</button> :
      <React.Fragment>
        <div className="newsletter-success">SUCCESSFULLY SUBMITTED</div>
        <div className="newsletter-thanks">THANK YOU FOR SIGNING UP</div>
      </React.Fragment>

    return (
      <React.Fragment>
        <div className="newsletter-title">SIGN UP FOR OUR NEWSLETTER</div>
        <div className="newsletter-subtitle">GET NOTIFIED WITH NEW FEATURES, MORE DATA, SALES DATA, TRENDS, AND NEWS</div>
        <Row>
          <Col xs={12} className="newsletter-field-description">REQUIRED FIELDS</Col>
          <Col xs={12} sm={6}>
            <input id="first_name" className="newsletter-first-name" type="text" placeholder="FIRST NAME" value={this.state.first_name} onChange={this.setUserInputText}/>
          </Col>
          <Col xs={12} sm={6}>
            <input id="last_name" className="newsletter-last-name" type="text" placeholder="LAST NAME" value={this.state.last_name} onChange={this.setUserInputText}/>
          </Col>
          <Col xs={12}>
            <input id="email" className="newsletter-email" type="text" placeholder="EMAIL" value={this.state.email} onChange={this.setUserInputText}/>
          </Col>
          <Col xs={12} className="newsletter-field-description">TELL US ABOUT YOURSELF</Col>

          <Col className="newsletter-section" xs={12} sm={6}>
            <div className="newsletter-category">HOW MUCH DO YOU SPEND ON CARDS PER MONTH?</div>
            <div id="spend" onChange={this.setUserInputRadio} className="newsletter-choice">
              <div><input type="radio" checked={this.state.spend === "less than $20"} value="less than $20"/>Less than $20</div>
              <div><input type="radio" checked={this.state.spend === "$20-$50"} value="$20-$50"/>$20-$50</div>
              <div><input type="radio" checked={this.state.spend === "$50-$100"} value="$50-$100"/>$50-$100</div>
              <div><input type="radio" checked={this.state.spend === "$100-$500"} value="$100-$500"/>$100-$500</div>
              <div><input type="radio" checked={this.state.spend === "$500-$1000"} value="$500-$1000"/>$500-$1000</div>
              <div><input type="radio" checked={this.state.spend === "over $1000"} value="over $1000"/>OVER $1000</div>
            </div>
          </Col>
          <Col className="newsletter-section" xs={12} sm={6}>
            <div className="newsletter-category">WHAT KIND OF CARDS DO YOU COLLECT?</div>
            <div className="newsletter-choice">
              <div><input type="checkbox" value="basketball" onChange={this.setUserInputCheckbox}/>BASKETBALL</div>
              <div><input type="checkbox" value="baseball" onChange={this.setUserInputCheckbox}/>BASEBALL</div>
              <div><input type="checkbox" value="football" onChange={this.setUserInputCheckbox}/>FOOTBALL</div>
              <div><input type="checkbox" value="pokemon" onChange={this.setUserInputCheckbox}/>POKEMON</div>
              <div><input type="checkbox" value="magic" onChange={this.setUserInputCheckbox}/>MAGIC</div>
              <div><input type="checkbox" value="yugioh" onChange={this.setUserInputCheckbox}/>YUGIOH</div>
              <div><input type="checkbox" value="other" onChange={this.setUserInputCheckbox}/>OTHER</div>
            </div>
          </Col>
          <Col className="newsletter-section" xs={12} sm={6}>
            <div className="newsletter-category">WOULD YOU DESCRIBE YOURSELF AS A CARD:</div>
            <div id="collector_type" className="newsletter-choice" onChange={this.setUserInputRadio}>
              <div><input type="radio" checked={this.state.collector_type === "hobby enthusiast"} value="hobby enthusiast"/>HOBBY ENTHUSIAST</div>
              <div><input type="radio" checked={this.state.collector_type === "investor"} value="investor"/>INVESTOR</div>
              <div><input type="radio" checked={this.state.collector_type === "both hobby enthusiast and investor"} value="both hobby enthusiast and investor"/>BOTH HOBBY ENTHUSIAST AND INVESTOR</div>
            </div>
          </Col>
          <Col className="newsletter-section" xs={12} sm={6}>
            <div id="age" className="newsletter-choice" onChange={this.setUserInputRadio}>
              <div className="newsletter-category">HOW OLD ARE YOU?</div>
              <div><input type="radio" checked={this.state.age === "under 12"} value="under 12"/>UNDER 12</div>
              <div><input type="radio" checked={this.state.age === "13-18"} value="13-18"/>13-18</div>
              <div><input type="radio" checked={this.state.age === "19-25"} value="19-25"/>19-25</div>
              <div><input type="radio" checked={this.state.age === "26-35"} value="26-35"/>26-35</div>
              <div><input type="radio" checked={this.state.age === "36-50"} value="36-50"/>36-50</div>
              <div><input type="radio" checked={this.state.age === "over 50"} value="over 50"/>OVER 50</div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="newsletter-agreement"><input type="checkbox" onChange={this.setUserAgree}/>YES I WANT TO RECEIVE EMAILS ABOUT GIVEAWAYS, NEW FEATURES, DATA AND NEWS FROM CENTER STAGE</div>
          </Col>
          <Col xs={12} className="newsletter-button-container">
            {button_or_message}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default NewsLetter;
