import React, {Component} from 'react';

class BestOfferInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      error: ''
    }
  }

  handleInput = (e) => {
    // this.setState({
    //   url: e.target.value
    // })
  };

  findBestOffer = (e) => {
    // if (e.keyCode === 13) {
    //   if ((this.state.url.length === 12 && isNaN(this.state.url)) || this.state.url.length < 12) {
    //     this.setState({
    //       error: 'Please enter a valid URL or ID'
    //     })
    //   }
    //   else if (this.state.url.length === 12) {
    //     window.location.href = `${window.location.origin}/${this.state.url}`;
    //   }
    //   else {
    //     window.location.href = `${window.location.origin}/${this.state.url.split('.com/')[1]}`;
    //   }
    // }
  };

  render() {
    let error = <div>&nbsp;</div>;
    if (this.state.error) {
      error = <div className="home-error">{this.state.error}</div>
    }
    return (
      <React.Fragment>
        <input disabled className="home-input" value={this.state.url} onKeyDown={this.findBestOffer} onChange={this.handleInput} type="text"
               placeholder="Temporarily disabled until further notice" />
        {error}
      </React.Fragment>
    )
  }
}

export default BestOfferInput;
