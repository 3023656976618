export default function(state = {name: [], brand: [], variation: []}, action) {
  switch (action.type) {
    case 'SET_NAME_OPTIONS':
      state.name = action.payload;
      return state;
    case 'SET_BRAND_OPTIONS':
      state.brand = action.payload;
      return state;
    case 'SET_VARIATION_OPTIONS':
      state.variation = action.payload;
      return state;
    default:
      return state;
  }
}
