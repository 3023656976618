import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";
import React from "react";

function PriceTrackerGraph(props) {
  const color = {
    PSA10: "#8884d8", PSA9: "#82ca9d", RAW: "red"
  };

  const tooltip = (value) => {
    // console.log(value);
    let html = [];
    try {
      html = [<div style={{fontWeight: 'bold', fontSize: 'larger'}}>{value.payload[0].payload.month}</div>];

      for (let key in value.payload[0].payload) {
        // console.log(value.payload[0].payload[key]);
        if (key !== 'month') {
          const val = value.payload[0].payload[key];
          const num = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          html.push(<div style={{color: color[key]}}>&nbsp;{key}: ${num}</div>);
        }
      }
      return html;
    }catch {
      return '';
    }
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{textAlign: 'center'}}>
        {
          payload.map((entry, index) => (
            <span style={{color: color[entry.value], fontWeight: 'bold'}} key={`item-${index}`}> {entry.value} </span>
          ))
        }
      </div>
    );
  };

  const customizedXAxisTick = (props) => {
    const {x, y, stroke, payload} = props;
    const timeFormat = moment(payload.value, "MMM YYYY").format('MMM YYYY');

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={30} y={0} dy={16} textAnchor="end" fill="#666">{timeFormat}</text>
        {/* <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text> */}
      </g>
    );
  };

  return (
    <div className="margin-top25">
      <ul className="price-tracker-filter-buttons">
        <li><button value='1' onClick={props.onClickHandler}>1M</button></li>
        <li><button value='6' onClick={props.onClickHandler}>6M</button></li>
        <li><button value='12' onClick={props.onClickHandler}>1Y</button></li>
      </ul>
      <ResponsiveContainer width='100%' aspect={5.0/2.0}>
        <LineChart fill="red" data={props.data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="month" interval="preserveStartEnd" tick={customizedXAxisTick}/>
          <YAxis width={40} tickFormatter={(label) => { return '$' + label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}/>
          <Tooltip className="price-tracker-tooltip" content={tooltip}/>
          <Legend content={renderLegend} />
          <Line type="monotone" dataKey="PSA10" stroke="#8884d8" />
          <Line type="monotone" dataKey="PSA9" stroke="#82ca9d" />
          <Line type="monotone" dataKey="RAW" stroke="red" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PriceTrackerGraph;
