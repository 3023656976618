import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import './BestOffer.css';
import axios from 'axios';
import BestOfferImages from "./best-offer-images/BestOfferImages";
import BestOfferDetails from "./best-offer-details/BestOfferDetails";
import {Helmet} from "react-helmet";
import MoreFeatures from "../more-features/MoreFeatures";

class BestOffer extends Component {
  constructor(props) {
    super();
    this.state = {
      data: null
    }
  }

  // componentDidMount() {
  //   this.findCardDetails();
  // }

  // findCardDetails = () => {
  //   let url = window.location.href;
  //   //ebay id should be length of 12
  //   try {
  //     let ebay_id = url.match(/\d{12}/)[0];
  //     if (ebay_id) {
  //       axios.get(`https://ziqszwc4l5.execute-api.us-west-2.amazonaws.com/dev/best-offer`, {params: {ebay_id}}).then((response) => {
  //         if (response.status === 500) {
  //           this.setState({
  //             error: 'Apologies an error has occurred, please check to see if the url is valid and try again.'
  //           })
  //         }
  //         else {
  //           console.log(response.data);
  //           this.setState({
  //             data: response.data
  //           })
  //        }
  //       }).catch((error) => {
  //         this.setState({
  //           error: 'Apologies an error has occurred, please check to see if the url is valid and try again.'
  //         })
  //       });
  //     }
  //     else {
  //       this.setState({
  //         error: 'Apologies an error has occurred, please check to see if the url is valid and try again.'
  //       })
  //     }
  //   }catch(err) {
  //     this.setState({
  //       error: 'Apologies an error has occurred, please check to see if the url is valid and try again.'
  //     })
  //   }
  // };

  render() {
    return (
      <h1>We are temporarily disabling the best offer feature until further notice.  Sorry for the inconvenience.</h1>
    )
    // if (this.state.error) {
    //   return (
    //     <Row className="height-100">
    //       <Col xs={12} className="loader-container">
    //         <div className="best-offer-error">{this.state.error}</div>
    //       </Col>
    //     </Row>
    //   )
    // }
    // if (!this.state.data) {
    //   return (
    //     <Row className="height-100">
    //       <Col xs={12} className="loader-container">
    //         <div id="loader"/>
    //       </Col>
    //     </Row>
    //   )
    // }
    // else {

    //   let title = this.state.data && this.state.data.details ?
    //     <Helmet>
    //       <meta charSet="utf-8" />
    //       <title>{this.state.data.title}</title>
    //     </Helmet> : '';

    //   return (
    //     <React.Fragment>
    //       {title}
    //       <Row>
    //         <BestOfferImages images={this.state.data.image}/>
    //         <BestOfferDetails data={this.state.data} />
    //         {/*<MoreFeatures />*/}
    //       </Row>
    //     </React.Fragment>
    //   )
    // }
  }
}

export default BestOffer;
