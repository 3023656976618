import React, {useEffect, useState} from 'react';
import {Row, Col, Tabs, Tab, Tooltip, OverlayTrigger} from "react-bootstrap";
import axios from 'axios';
import helpers from '../helper/bootstrapTable';
import BootstrapTable from 'react-bootstrap-table-next';
import '../bootstrap-table/BootstrapTable.css';
import moment from "moment-timezone";
import MoreFeatures from "../more-features/MoreFeatures";
import './LargestMovers.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

function LargestMovers(props) {
  let [positive_data, setPositive] = useState([]);
  let [negative_data, setNegative] = useState([]);
  let [found_data, setFound] = useState(false);
  let [last_updated, setUpdated] = useState(null);

  useEffect(() => {
    if (!found_data) {
      getData();
    }
  });

  const getData = () => {
    axios.get(`https://su8lv54z5c.execute-api.us-west-2.amazonaws.com/dev`).then((response) => {
      const positives = [], negatives = [];
      const items = response.data.Items;
      for (let item of items) {
        item['total_change'] = Math.round((item.current_price - item.previous_price) * 100) / 100;
      }
      items.forEach((item) => {
        if (item.percent_change >= 0) {
          positives.push(item);
        } else {
          negatives.push(item);
        }
      })
      positives.sort((a,b) => a.percent_change < b.percent_change ? 1 : -1);
      negatives.sort((a,b) => a.percent_change < b.percent_change ? -1 : 1);
      setPositive(positives);
      setNegative(negatives);
      // console.log(positives);

      let timezone = moment.tz.guess();
      if(positives[0].created_at) {
        // issue : rendering in UTC time zone. need to fix
        setUpdated(`Last updated: ${moment(positives[0].created_at).tz(timezone).format('MMMM Do YYYY, h:mm:ss a')}`);
      } else if(negatives[0].created_at) {
        // issue : rendering in UTC time zone. need to fix
        setUpdated(`Last updated: ${moment(negatives[0].created_at).tz(timezone).format('MMMM Do YYYY, h:mm:ss a')}`);
      }
    }).catch((error) => {
      console.log('in this error');
      console.log(error);
    });
    setFound(true);
  };

  const sortIcon = (order, column) => {
    if (!order) return (<span className="caret">&nbsp;&nbsp;</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;Desc/<font color="red">Asc</font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red">Desc</font>/Asc</span>);
    return null;
  };

  const columns = [
    {dataField: 'title', text: 'Card', sort: true, headerAttrs: {colSpan: "4"}, attrs: {colSpan: "4"}},
    // {dataField: 'slab_link', text: 'Card', sort: true, sortFunc: helpers.sortLink},
    {dataField: 'percent_change', text: '% Change', sort: true, sortFunc: helpers.sortNum, align: 'right',
      headerAlign: 'right', headerAttrs: {colSpan: "2"}, attrs: {colSpan: "2"}},
    {dataField: 'current_price', text: '7 day avg', sort: true, sortFunc: helpers.sortNum, align: 'right', headerAlign: 'right', headerAttrs: {colSpan: "2"}, attrs: {colSpan: "2"}},
    {dataField: 'previous_price', text: 'Prev 7 day avg', sort: true, sortFunc: helpers.sortNum, align: 'right', headerAlign: 'right', headerAttrs: {colSpan: "2"}, attrs: {colSpan: "2"}},
    {dataField: 'total_change', text: 'Total Change', sort: true, sortFunc: helpers.sortNum, align: 'right', headerAlign: 'right', headerAttrs: {colSpan: "2"}, attrs: {colSpan: "2"}},
  ];

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1 className="unusual-title">Largest Movers</h1>
          <div className="unusual-title-subtext">
            Provides insight on cards that are becoming "hot and cold". A higher percent change may signal that interest
            in a card is increasing.  Only cards with 5 verified sales in each of the last 2 weeks are included.
          </div>
          <div className="last-updated">{last_updated}</div>
          <Tabs defaultActiveKey="positive" id="table">
            <Tab eventKey="positive" title="Advancers">
              <BootstrapTable keyField='card' data={ positive_data } bootstrap4
                              pagination={ paginationFactory() }
                              columns={ helpers.setColorToColumns(columns.slice(), 'positive-col') }
                              headerClasses="analytic-table-header" rowClasses="analytic-table-row" />
            </Tab>
            <Tab eventKey="negative" title="Decliners">
              <BootstrapTable keyField='card' data={ negative_data } bootstrap4
                              pagination={ paginationFactory() }
                              columns={ helpers.setColorToColumns(columns.slice(),'negative-col') }
                              headerClasses="analytic-table-header" rowClasses="analytic-table-row" />
            </Tab>
          </Tabs>
          <MoreFeatures />
        </Col>
      </Row>
    </React.Fragment>
  )

}

export default LargestMovers;
